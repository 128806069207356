<template>
  <div class="page page-xtrading-all-campaign-rules height-100">

    <Loading :value="loading" />
    <v-dialog width="500px" overlay-color="primary" v-model="showDialogRule" persistent>
      <v-card class="pa-4">
        <v-form v-model="formRule" ref="formRule">
          <template v-if="selectedRule">
            <v-toolbar dense flat>
              <v-toolbar-title>
                <h3>{{ $t(selectedRuleNumber !== -1 ? 'CAMPAIGN_RULE.FORM_RULE_UPDATE' : "CAMPAIGN_RULE.FORM_RULE_CREATE") }}</h3>
              </v-toolbar-title>
              <v-spacer />
              <v-btn fab x-small color="grey lighten-3" class="mr-2" depressed @click="closeRule"><v-icon>mdi-close</v-icon></v-btn>
              <v-btn fab small color="success" depressed @click="saveRule" :disabled="!formRule"><v-icon>mdi-check</v-icon></v-btn>
            </v-toolbar>
            <v-row class="ma-0">
              <v-col cols="12">
                <!-- <p>{{$t('CAMPAIGN_RULE.FIELD_NAME')}}</p> -->
                <v-text-field
                  v-model="selectedRule.name"
                  outlined
                  dense
                  clearable
                  :label="$t('CAMPAIGN_RULE.FIELD_NAME')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12">
                <v-select
                  outlined
                  dense
                  :rules="[$rules.required]"
                  :label="$t('CAMPAIGN_RULE.FIELD_STATUS')"
                  v-model="selectedRule.status"
                  :items="$t('CAMPAIGN_RULE.STATUS_ITEMS')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="6">
                <v-text-field
                v-model="selectedRule.limit"
                outlined
                dense
                clearable
                :label="$t('CAMPAIGN_RULE.FIELD_LIMIT')"
                type="number"
                :min="selectedRule.count || 0"
                step="1"
                hide-details
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                v-model="selectedRule.count"
                outlined
                dense
                clearable
                :label="$t('CAMPAIGN_RULE.FIELD_COUNT')"
                disabled
                />
              </v-col>
            </v-row>
          </template>

        </v-form>
      </v-card>
    </v-dialog>


    <!-- EVENT -->
    <v-dialog width="800px" overlay-color="primary" v-model="showDialogEvent" persistent>
      <v-card class="pa-4">
        <v-form v-model="formEvent" ref="formEvent">
          <template v-if="selectedEvent">
            <v-toolbar dense flat>
              <v-toolbar-title>
                <h3>{{ $t(selectedEventNumber !== -1 ? 'CAMPAIGN_RULE.FORM_EVENT_UPDATE' : "CAMPAIGN_RULE.FORM_EVENT_CREATE") }}</h3>
              </v-toolbar-title>
              <v-spacer />
              <v-btn fab x-small color="grey lighten-3" class="mr-2" depressed @click="closeEvent"><v-icon>mdi-close</v-icon></v-btn>
              <v-btn fab small color="success" depressed @click="saveEvent" :disabled="!formEvent"><v-icon>mdi-check</v-icon></v-btn>
            </v-toolbar>
            <v-row class="ma-0">
              <v-col cols="12">
                <p>{{$t('CAMPAIGN_RULE.FIELD_EVENT_NAME')}}</p>
                <v-select
                  outlined
                  dense
                  :rules="[$rules.required]"
                  v-model="selectedEvent.name"
                  :items="$t('CAMPAIGN_RULE.EVENT_NAME_ITEMS')"
                  hide-details
                />
              </v-col>
            </v-row>
            <template v-if="selectedEvent.name && selectedEvent.name.includes('campaign_job_')">
              <v-row class="ma-0">
                <v-col cols="12">
                  <p>{{$t('CAMPAIGN_RULE.FIELD_EVENT_FILTERS')}}</p>
                  <v-select
                    outlined
                    dense
                    multiple
                    small-chips
                    clearable
                    :label="$t('JOB.FIELD_SECTOR')"
                    v-model="selectedEvent.filters.xtramile_sector_id"
                    :items="$t('JOB.XTRAMILE_SECTORS_ITEMS')"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    outlined
                    dense
                    multiple
                    small-chips
                    clearable
                    :label="$t('JOB.FIELD_OCCUPATION')"
                    v-model="selectedEvent.filters.xtramile_occupation_id"
                    :items="$t('JOB.XTRAMILE_OCCUPATIONS_ITEMS')"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    outlined
                    dense
                    multiple
                    small-chips
                    clearable
                    :label="$t('JOB.FIELD_EXPERIENCE_LEVEL')"
                    v-model="selectedEvent.filters.experience_level"
                    :items="$t('JOB.EXPERIENCE_LEVEL_ITEMS')"
                    hide-details
                  />
                </v-col>

                <v-col cols="12" class="d-flex">
                  <p>{{$t('CAMPAIGN_RULE.FIELD_CUSTOM')}}</p>
                  <v-spacer />
                  <v-btn
                    x-small
                    depressed
                    fab
                    color="secondary black--text"
                    @click="selectedEvent.filters.custom.push({name:'', op:'eq', values:[]})"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <div :key="'fi'+i" v-for="(filter, i) in (selectedEvent.filters.custom)" class="mb-2">
                  <v-row class="mr-0 ml-0">
                    <v-col cols="4" class="pr-1">
                      <v-text-field
                        v-model="selectedEvent.filters.custom[i].name"
                        required
                        :rules="[$rules.required]"
                        :placeholder="$t('CAMPAIGN_RULE.FIELD_CUSTOM_NAME')"
                        outlined
                        dense
                        hide-details
                      />
                    </v-col>

                    <v-col cols="3" class="pr-0 pl-0">
                      <v-select
                        v-model="selectedEvent.filters.custom[i].op"
                        outlined
                        dense
                        :rules="[$rules.required]"
                        :label="$t('CAMPAIGN_RULE.FIELD_CUSTOM_OPERATOR')"
                        :items="$t('CAMPAIGN_RULE.CUSTOM_OPERATORS_ITEMS')"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="4" class="pl-1">
                      <v-combobox
                        v-model="selectedEvent.filters.custom[i].values"
                        outlined
                        dense
                        multiple
                        small-chips
                        clearable
                        :rules="[$rules.required]"
                        :label="$t('CAMPAIGN_RULE.FIELD_CUSTOM_VALUES')"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="1" class="pr-0 pl-0">
                      <v-btn
                        small
                        depressed
                        icon
                        @click="selectedEvent.filters.custom.splice(i, 1)"
                        class="text-center mt-1"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>

                  </v-row>
                </div>

              </v-row>
            </template>
          </template>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- ACTION -->
    <v-dialog width="600px" overlay-color="primary" v-model="showDialogAction" persistent>
      <v-card class="pa-4">
        <v-form v-model="formAction" ref="formAction">
          <template v-if="selectedAction">
            <v-toolbar dense flat>
              <v-toolbar-title>
                <h3>{{ $t(selectedActionNumber !== -1 ? 'CAMPAIGN_RULE.FORM_ACTION_UPDATE' : "CAMPAIGN_RULE.FORM_ACTION_CREATE") }}</h3>
              </v-toolbar-title>
              <v-spacer />
              <v-btn fab x-small color="grey lighten-3" class="mr-2" depressed @click="closeAction"><v-icon>mdi-close</v-icon></v-btn>
              <v-btn fab small color="success" depressed @click="saveAction" :disabled="!formAction"><v-icon>mdi-check</v-icon></v-btn>
            </v-toolbar>
            <v-row class="ma-0">
              <v-col cols="12">
                <p>{{$t('CAMPAIGN_RULE.FIELD_ACTION_NAME')}}</p>
                <v-select
                  outlined
                  dense
                  v-model="selectedAction.name"
                  :items="$t('CAMPAIGN_RULE.ACTION_NAME_ITEMS')"
                  hide-details
                />
              </v-col>
            </v-row>
            <template v-if="selectedAction.name && selectedAction.name.includes('publication_')">
              <v-row class="ma-0">
                <v-col cols="12">
                  <p>{{$t('CAMPAIGN_RULE.FIELD_ACTION_VALUES')}}</p>
                  <!-- item-text="channel.title"
                  item-value="id" -->
                  <v-select
                    outlined
                    dense
                    :rules="[$rules.required]"
                    :label="$t('CHANNEL_SUBSCRIPTION.TITLE')"
                    v-model="selectedAction.values.channel_subscription_id"
                    :items="channelSubscriptionItemsSelect"
                    hide-details
                    @change="v => setCurrentChannelSubscription(v)"
                  />
                </v-col>
                <v-col cols="12" class="none">
                  <v-text-field
                  v-model="selectedAction.values.channel_id"
                  />
                  <v-text-field
                  v-model="selectedAction.values.type"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0" v-if="channelSubscriptionItem">
                <v-col cols="6">
                  <v-select
                    outlined
                    dense
                    :rules="[$rules.required]"
                    :label="$t('INPUT.METHOD')"
                    v-model="selectedAction.values.method"
                    :items="channelSubscriptionItem.methods.map(v => ({ text:$t('PUBLICATION.METHOD_' + (v || 'UNKNOWN').toUpperCase()), value: v }))"
                    hide-details
                    @change="applyPrice"
                  />
                </v-col>
                <v-col cols="6" v-if="selectedAction.values.method === 'organic'">
                  <v-text-field
                    v-model="selectedAction.values.price"
                    class="none"
                  />
                </v-col>
                <v-col cols="6" v-else-if="selectedAction.values.method === 'credit'">
                  <v-text-field
                    v-model="selectedAction.values.price"
                    class="none"
                  />
                </v-col>
                <v-col cols="6" v-else-if="selectedAction.values">
                  <v-text-field
                    v-model="selectedAction.values.price"
                    outlined
                    dense
                    clearable
                    :label="$t('INPUT.PRICE')"
                    type="number"
                    min="0"
                    step="0.01"
                    hide-details
                    :rules="[$rules.min(0)]"
                  />
                </v-col>
              </v-row>
            </template>

          </template>
        </v-form>
      </v-card>
    </v-dialog>


    <!-- BODY -->
    <v-row class="ma-0 ml-4 mr-4 height-100 d-flex flex-column">
      <v-col cols="12" class="height-100 d-flex flex-column pb-0">
        <div class="mb-6">
          <h3 class="primary--text">
            <v-icon size="24" class="mb-1 mr-2" color="primary">{{$t('CAMPAIGN_RULE.ICON')}}</v-icon>
            <span>{{$t('CAMPAIGN_RULE.TITLES')}}</span>
          </h3>
          <p class="grey--text">{{$tc('CAMPAIGN_RULE.TITLE_COUNT',+rules.length)}}</p>
          <v-btn rounded depressed color="primary" @click="addRule"><v-icon class="mr-2">mdi-plus</v-icon>{{$t('CAMPAIGN_RULE.ADD_RULE')}}</v-btn>
        </div>
        <div class="flex-grow scroll">
          <v-expansion-panels flat v-model="panel">
            <v-expansion-panel
              v-for="(rule,i) in rules"
              :key="i"
              class="pa-0 bordered"
            >

              <v-expansion-panel-header class="pa-2">
                <div class="d-flex">
                  <div class="pa-2 primary white--text rounded">{{rule.name}}</div>
                  <div class="pa-1 ml-4">
                    <v-icon size="24" v-if="rule.status === 1" color="success">mdi-check-circle</v-icon>
                    <v-icon size="24" v-else color="error">mdi-close-circle</v-icon>
                  </div>
                  <div class="pa-2">
                    <span :class="[rule.events.length === 0 ? 'error--text' : '', 'mr-4']">{{$tc('CAMPAIGN_RULE.EVENT_COUNT', rule.events.length || 0)}}</span>
                    <span :class="[rule.actions.length === 0 ? 'error--text' : '', 'mr-4']">{{$tc('CAMPAIGN_RULE.ACTION_COUNT', rule.actions.length || 0)}}</span>
                    <span v-if="rule.limit > 0" :class="[rule.count >= rule.limit ? 'error--text' : '']">{{$t('CAMPAIGN_RULE.FIELD_LIMIT')}}: <span class="grey--text">{{rule.count}}/</span>{{rule.limit}}</span>
                  </div>
                  <v-spacer />
                  <div class="mr-6">
                    <v-btn icon class="mr-2" @click.stop="selectRule(i)"><v-icon>mdi-cog</v-icon></v-btn>
                    <v-btn icon @click.stop="removeRule(i)"><v-icon>mdi-delete</v-icon></v-btn>
                  </div>

                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-divider />
                <v-row class="ma-0">
                  <v-col cols="6" class="pa-0">
                    <div class="pa-4 pt-2 pb-2">
                      <span><strong>{{$t('CAMPAIGN_RULE.FIELD_EVENT')}}</strong></span><v-btn small depressed rounded class="ml-4" color="grey lighten-2" @click="addEvent(i)"><v-icon size="16" class="mr-2">mdi-plus</v-icon>{{$t('COMMON.ADD')}}</v-btn>
                    </div>
                    <v-divider />
                    <v-list class="pa-0 height-max-40vh scroll">
                      <template v-for="(event,j) in rule.events">
                        <v-list-item :key="'ev'+i+j">
                          <v-list-item-avatar color="primary"><v-icon color="white">{{$t('CAMPAIGN_RULE.EVENT_NAMES.'+ (event.name || 'untitled').toUpperCase() + '_ICON')}}</v-icon></v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{$t('CAMPAIGN_RULE.EVENT_NAMES.'+ (event.name || 'untitled').toUpperCase())}}</v-list-item-title>
                            <v-list-item-subtitle v-for="(key, k) in Object.keys(event.filters)" :key="'fil'+k">
                              <small>{{key}}: {{ JSON.stringify(event.filters[key]) }}</small>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div>
                              <v-btn icon class="mr-2" @click="selectEvent(i, j)"><v-icon>mdi-cog</v-icon></v-btn>
                              <v-btn icon @click="removeEvent(i, j)"><v-icon>mdi-delete</v-icon></v-btn>
                            </div>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                  <v-divider vertical />
                  <v-col cols="6" class="pa-0">
                    <div class="pa-4 pt-2 pb-2">
                      <span><strong>{{$t('CAMPAIGN_RULE.FIELD_ACTION')}}</strong></span><v-btn small depressed rounded class="ml-4" color="grey lighten-2" @click="addAction(i)"><v-icon size="16" class="mr-2">mdi-plus</v-icon>{{$t('COMMON.ADD')}}</v-btn>
                    </div>
                    <v-divider />
                    <v-list class="pa-0 height-max-40vh scroll">
                      <template v-for="(action,j) in rule.actions">
                        <v-list-item :key="'act'+i+j">
                          <v-list-item-avatar color="primary"><v-icon color="white">{{$t('CAMPAIGN_RULE.ACTION_NAMES.'+ (action.name || 'untitled').toUpperCase() + '_ICON')}}</v-icon></v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{$t('CAMPAIGN_RULE.ACTION_NAMES.'+ (action.name || 'untitled').toUpperCase())}}</v-list-item-title>
                            <v-list-item-subtitle v-if="action.values.channel_subscription_id">
                              <small><span>{{$t('CHANNEL_SUBSCRIPTION.TITLE')}}: </span>
                                <strong>{{ getChannelSubscription('channel.title', action.values.channel_subscription_id) }}</strong>
                                <strong>{{ getChannelSubscription('alias', action.values.channel_subscription_id) ?
                                    ' (' + getChannelSubscription('alias', action.values.channel_subscription_id) + ')' : '' }}
                                </strong>
                              </small>
                            </v-list-item-subtitle>
                            <template v-for="(key, k) in Object.keys(action.values)">
                            <v-list-item-subtitle :key="'fil'+k" v-if="key !== 'channel_subscription_id' && key !== 'channel_id'">
                              <small>{{key}}: {{action.values[key]}}</small>
                            </v-list-item-subtitle>
                            </template>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div>
                              <v-btn icon class="mr-2" @click="selectAction(i, j)"><v-icon>mdi-cog</v-icon></v-btn>
                              <v-btn icon @click="removeAction(i, j)"><v-icon>mdi-delete</v-icon></v-btn>
                            </div>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>

  </div>
</template>


<script>
import Loading from '@/components/Loading'

export default {
  name: 'PageXtradingAllCampaignRules',
  components: {
    Loading,
  },
  data: () => ({
    loading: false,
    panel: 0,
    // action
    formAction: false,
    showDialogAction: false,
    selectedActionNumber: -1,
    selectedAction: null,
    defaultAction: {
      name: null,
      values: {
        type: undefined,
        channel_id: undefined,
        channel_subscription_id: undefined,
        price: undefined,
        method: undefined,
      },
      where: {}
    },
    // event
    formEvent: false,
    showDialogEvent: false,
    selectedEventNumber: -1,
    selectedEvent: null,
    defaultEvent: {
      name: null,
      filters: {
        xtramile_sector_id: [],
        xtramile_occupation_id: [],
        experience_level: [],
        custom: []
      }
    },
    // rule
    formRule: false,
    showDialogRule: false,
    selectedRule: null,
    selectedRuleNumber: -1,
    rules: [],
    defaultRule: {
      name: null,
      status: 1,
      limit: 0,
      count: 0,
      actions: [],
      events: []
    },
    //
    campaignItem: null,
    channelSubscriptionItem: null,
    channelSubscriptionItems: [],
  }),
  computed: {
    channelSubscriptionItemsSelect () {
      return this.channelSubscriptionItems.map(v => ({text:v.channel.title + (v.alias ? ` (${v.alias})` : ''), value: v.id}))
    },
    campaignId() {
      return this.$router.currentRoute.params.id || undefined;
    },
    currentRule () {
      if (this.rules[this.selectedRule]) return this.rules[this.selectedRule]
      return undefined
    },
  },
  created () {
    this.loadCampaignItem()
      .then(() => {
        this.loadChannelSubscriptionItems()
      })
  },
  watch: {
    selectedAction(v) {
      this.setCurrentChannelSubscription(v.values.channel_subscription_id)
    },
  },
  methods: {

    getChannelSubscription (key, channel_subscription_id) {
      for (const cs of this.channelSubscriptionItems) {
        if (cs.id === channel_subscription_id) {
          return this.$nestedData(key, cs)
        }
      }
    },

    applyPrice (method) {
      this.selectedAction.values.price = 0
      if (method === 'organic') {
        this.selectedAction.values.price = 0
      } else if (method === 'credit') {
        this.selectedAction.values.price = 1
      }
      if (this.channelSubscriptionItem && this.channelSubscriptionItem.prices && this.channelSubscriptionItem.prices[method]) {
        this.selectedAction.values.price = this.channelSubscriptionItem.prices[method]
      }
    },

    setCurrentChannelSubscription (id) {
      if (id) {
        for (const cs of this.channelSubscriptionItems) {
          if (cs.id === id) {
            this.channelSubscriptionItem = cs
            if (this.selectedAction) {
              const method = this.selectedActionNumber !== -1 ? null : cs.methods[0]
              this.selectedAction.values.method = this.selectedActionNumber !== -1 ? this.selectedAction.values.method : (method || undefined)
              this.selectedAction.values.channel_id = cs.channel_id || undefined
              this.selectedAction.values.type = 'offer'
              if (method) {
                this.applyPrice(method)
              }
            }
            return
          }
        }
      }
      return this.channelSubscriptionItem = null
    },

    loadCampaignItem () {
      return this.$services.api_programmatic.campaign.search({where:{id: this.campaignId}})
        .then(response => {
          if (response.data && response.data[0]) {
            this.campaignItem = response.data[0]
            this.rules = this.$clone(this.campaignItem.rules)
          }
        })
    },

    loadChannelSubscriptionItems () {
      return this.$services.api_programmatic.channel_subscription.search({
        where:{
          category: this.campaignItem.channel_categories,
          status: 1,
          channel: true,
        }
      })
        .then(response => {
          if (response.data && response.data[0]) {
            this.channelSubscriptionItems = response.data
          }
        })
    },

    // RULE

    closeRule () {
      if (this.selectedRuleNumber === -1) this.$nextTick(() => {
        setTimeout(() => {
          this.panel = this.rules.length-1
        }, 100)
      })
      this.selectedRuleNumber = -1
      this.selectedRule = this.$clone(this.defaultRule)
      this.showDialogRule = false
    },

    addRule () {
      if (this.rules) {
        this.selectedRuleNumber = -1
        this.selectedRule = this.$clone(this.defaultRule)
        this.selectedRule.name = this.$i18n.t('CAMPAIGN_RULE.FIELD_RULE') + ' ' + (this.rules.length+1)
        this.showDialogRule = true
      }
    },

    selectRule (i) {
      if (this.rules && this.rules[i]) {
        this.panel = i
        this.selectedRuleNumber = i
        this.selectedRule = this.$clone(this.rules[i])
        this.showDialogRule = true
      }
    },

    removeRule (i) {
      const rules = this.$clone(this.rules)
      if (rules && rules[i]) {
        rules.splice(i,1)
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(response => {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
            this.rules = rules
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    saveRule () {
      if (this.selectedRule) {
        const i = this.selectedRuleNumber
        const rule = this.$clone(this.selectedRule)
        const rules = this.$clone(this.rules)
        if (i === -1) {
          rules.push(rule)
        } else {
          rules[i] = rule
        }
        this.loading = true
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(response => {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
            this.rules = rules
            this.closeRule()
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


    // ACTION

    closeAction () {
      this.selectedActionNumber = -1
      this.selectedAction = this.$clone(this.defaultAction)
      this.showDialogAction = false
    },

    addAction (i) {
      if (this.rules && this.rules[i] && this.rules[i].actions) {
        this.selectedRuleNumber = i
        this.selectedRule = this.$clone(this.rules[i])
        this.selectedActionNumber = -1
        this.selectedAction = this.$clone(this.defaultAction)
        this.channelSubscriptionItem = null
        this.showDialogAction = true
      }
    },

    selectAction (i,j) {
      if (this.rules && this.rules[i] && this.rules[i].actions && this.rules[i].actions[j]) {
        this.selectedRuleNumber = i
        this.selectedRule = this.$clone(this.rules[i])
        this.selectedActionNumber = j
        this.selectedAction = this.$clone(this.rules[i].actions[j])
        this.showDialogAction = true
      }
    },

    removeAction (i,j) {
      const rules = this.$clone(this.rules)
      if (rules && rules[i] && rules[i].actions && rules[i].actions[j]) {
        rules[i].actions.splice(j,1)
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(() => {
            this.rules = rules
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    saveAction () {
      if (this.selectedAction) {
        const i = this.selectedRuleNumber
        const j = this.selectedActionNumber
        const action = this.$clone(this.selectedAction)
        const cleanFilters = (filters = {}) => {
          const newFilters = {}
          Object.keys(filters).forEach((key, j) => {
            const value = filters[key]
            if ((value instanceof Array && value.length > 0) || (typeof value !== 'object' && (value || value === 0))) {
              newFilters[key] = value
            }
          })
          return newFilters
        }
        action.values = cleanFilters(action.values)
        action.filters = cleanFilters(action.filters)
        const rule = this.$clone(this.selectedRule)
        const rules = this.$clone(this.rules)
        if (j === -1) {
          rules[i].actions.push(action)
        } else {
          rules[i].actions[j] = action
        }
        this.loading = true
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(response => {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
            this.rules = rules
            this.closeAction()
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


    // EVENT

    closeEvent () {
      this.selectedEventNumber = -1
      this.selectedEvent = this.$clone(this.defaultEvent)
      this.showDialogEvent = false
    },

    addEvent (i) {
      if (this.rules && this.rules[i] && this.rules[i].events) {
        this.selectedRuleNumber = i
        this.selectedRule = this.$clone(this.rules[i])
        this.selectedEventNumber = -1
        this.selectedEvent = this.$clone(this.defaultEvent)
        this.showDialogEvent = true
      }
    },

    selectEvent (i,j) {
      if (this.rules && this.rules[i] && this.rules[i].events && this.rules[i].events[j]) {
        this.selectedRuleNumber = i
        this.selectedRule = this.$clone(this.rules[i])
        this.selectedEventNumber = j
        const currentEvent = this.$clone(this.rules[i].events[j])
        currentEvent.filters = Object.assign(this.$clone(this.defaultEvent.filters), currentEvent.filters)
        this.selectedEvent = currentEvent
        this.showDialogEvent = true
      }
    },

    removeEvent (i,j) {
      const rules = this.$clone(this.rules)
      if (rules && rules[i] && rules[i].events && rules[i].events[j]) {
        rules[i].events.splice(j,1)
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(() => {
            this.rules = rules
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    saveEvent () {
      if (this.selectedEvent) {
        const i = this.selectedRuleNumber
        const j = this.selectedEventNumber
        const event = this.$clone(this.selectedEvent)
        const cleanFilters = (filters = {}) => {
          const newFilters = {}
          Object.keys(filters).forEach((key, j) => {
            const value = filters[key]
            if ((value instanceof Array && value.length > 0) || (typeof value !== 'object' && (value || value === 0))) {
              newFilters[key] = value
            }
          })
          return newFilters
        }
        event.filters = cleanFilters(event.filters)
        const rule = this.$clone(this.selectedRule)
        const rules = this.$clone(this.rules)
        if (j === -1) {
          rules[i].events.push(event)
        } else {
          rules[i].events[j] = event
        }
        this.loading = true
        this.$services.api_programmatic.campaign.update({values:{rules}, where: {id:this.campaignId}})
          .then(response => {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
            this.rules = rules
            this.closeEvent()
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

  },
}
</script>
